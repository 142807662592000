.create-goodie-bag-input {
  max-width: 700px;
}

.create-goodie-bag-step-two .custom-markdown-area-title {
  width: 100%;
  max-width: 700px;
}

.multiple-input-row {
  display: flex;
  flex-direction: row;

  label {
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 8px;

    font-weight: 800;
    font-size: 18px;
    line-height: 24px;

    color: #404040;
  }

  input {
    width: 30% !important;
    border-radius: 4px;
    border: 1px solid #C2C2C2;
    

    &:disabled {
      background: #F7F7F7;
    }
  }  
}

.font16-blod {
  font-size: 16px !important;
  font-weight: 800;

  margin-top: 20px !important;
}

.tip2 {
  white-space: break-spaces;
  line-height: 100%;
  font-size: 14px !important;
}

