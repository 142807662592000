.overall-amount-section-total {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    color: #4d5560;
    margin-top: 30px;
}

.goodie-bag-absolute-date-error {
    color: #ff0000;
}

.goodie-bag-overall-area {
    margin-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #DADADA;
    border-bottom: 1px solid #DADADA;
}

.goodie-bag-overall-area-tips {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px !important;
}