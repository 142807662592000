.create-banner {
  display: flex;
  flex-direction: column;

  .display-order-banner {
    padding-left: 10px;
  }
  .base-temp-save-area {
    align-self: center;
    margin-left: auto;
  }
  .type-margin-top {
    margin-top: 0px;
  }
  .landing-title-margin-bottom {
    margin-bottom: 10px;
  }

  .without-margin-top {
    margin-top: 0px;
  }
}

.platform-checkbox {
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.platform-checkbox-notfirst {
  margin-top: 0px;
  margin-left: 41px;
}

.wechat-url-input {
  margin-top: 8px;
  margin-bottom: 8px;
}
