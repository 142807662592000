.category-select-title-top {
  margin-top: -30px;
}

.offer-type-button {
  margin-top: 12px !important;
}

.category-frontend {
  font-family: Mulish;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;

  ul {
    font-weight: 700;
  }

  li {
    font-weight: 400;
    margin-left: 23px;
  }
} 

li > label.ant-checkbox-wrapper {
  margin-top: 0px;
  margin-bottom: auto;
}

.category-tag {
  color: #999999;
}