@import '../../../utils/Variables.scss';
.modal.show .modal-dialog {
  margin: 0 auto;
}
.base-prompt-container {
  background: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 40px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  .base-buttons-main {
    margin-right: 0;
  }
}

.base-prompt-close-button {
  width: 32px;
  height: 32px;
  top: 20px;
  right: 20px;
  position: absolute;
  object-fit: contain;
  align-self: flex-end;
}

.base-prompt-close-button:hover {
  cursor: pointer;
}

.base-prompt-header {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 33px;
  color: $lightBlack;
  align-self: flex-start;

  white-space: pre-line;
}

.base-prompt-description {
  max-width: 673px;
  align-self: flex-start;
  margin-top: 6px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $inkBlack;
}

.base-prompt-other-body {
  align-self: flex-start;
  width: 100%;
}

.base-prompt-buttons {
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
}

.extra-main-button-margin {
  margin-left: auto;
}

.create-prompt-continue {
  margin-top: 40px;
  align-self: flex-end;
  background: $navyBlue;
}

.modal-content {
  width: auto;
  min-width: 490px;
}

.modal-dialog {
  max-width: fit-content;
}
