@import '../../utils/Variables.scss';

.scroll-container-common {
  align-self: center;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.header-width {
  width: 100%;
}

.scroll-container-common-header {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  background: white;
  z-index: 1;
  width: 100%;
  // justify-content: center;
  display: flex;
  border-bottom: 1px solid #dadada;
}

.scroll-container-short-screen {
  width: 960px;
}

.caution-area {
  margin-top: 20px;
}

.caution-text {
  font-family: MuliExtraBold;
  font-size: 26px;
  line-height: 33px;
  color: $darkBlue;
  margin-bottom: 0px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.on-saving-area-active {
  margin-top: 30px;
  margin-bottom: 30px;
}
