.content-section-rectangle-image {
  width: 80px;
  height: 53px;
  object-fit: contain;
}

.content-section-square-image {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.content-section-table-container {
  margin-top: 30px;
}

.content-section-field-clumn {
  width: 139px;
  padding: 20px;
}

.content-section-value-clumn {
  width: 300px;
  text-align: center;
}

.content-section-detail-photo-container {
  display: flex;
  flex-direction: column;
}

.content-section-detail-photo {
  margin-bottom: 12px;
}

.campaign-detail-language-content-sections{
  margin-bottom: 0;
}