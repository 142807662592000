.goodie-bag-step-one-label {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  margin-bottom: 10px;
}

.goodiebag-slot-delete-icon {
  margin-left: auto;
}
